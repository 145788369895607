import { ApplicationCard, AppWrapper, Box, StylableHeading } from '@os/ui';
import { useTitle, useScreenSize } from '@os/hooks';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import clearReduxOnLogout from '../state/company-logout';

function employerAction() {
  window.location = '/employer';
}

function employeeAction() {
  window.location = '/employee';
}

function Welcome() {
  const { t, ready: translationReady } = useTranslation([
    'translation',
    'welcome',
  ]);
  const { sm, md } = useScreenSize();
  const { id } = useSelector(state => state.company.details);
  const dispatch = useDispatch();

  useTitle(t('welcome:title'));

  useEffect(() => {
    if (id) {
      dispatch(clearReduxOnLogout());
    }
  });

  if (!translationReady) {
    return null;
  }

  return (
    <AppWrapper>
      <>
        <StylableHeading
          title={t('welcome:title')}
          subheader={t('welcome:subheader')}
          separator
          divider={false}
          align="center"
          variant="h1-large"
          subheaderVariant="h2"
          paddingProps={{ pt: 4 }}
        />

        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <ApplicationCard
            action={employerAction}
            icon="shop"
            isMobile={sm || md}
            subtitle={t('welcome:employer-info')}
            text={t('welcome:go')}
            title={t('welcome:employer-title')}
          />
          <ApplicationCard
            action={employeeAction}
            icon="people"
            isMobile={sm || md}
            subtitle={t('welcome:employee-info')}
            text={t('welcome:go')}
            title={t('welcome:employee-title')}
          />
        </Box>
      </>
    </AppWrapper>
  );
}

export default memo(Welcome);
