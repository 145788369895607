/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useTitle } from '@os/hooks';
import { Box, StylableHeading, Link, Message, Typography } from '@os/ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const lang = 'ess-v1-deprecated';

function ESSV1DeprecationInfo() {
  const { t, ready: translationReady } = useTranslation(['translation', lang]);

  useTitle(t(`${lang}:title`));

  if (!translationReady) {
    return null;
  }

  return (
    <>
      <StylableHeading
        separator
        divider={false}
        title={t(`${lang}:title`)}
        align="center"
        variant="h1-large"
        paddingProps={{ pt: 2 }}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        bg="white"
        mt="3%"
        ml="15%"
        mr="15%"
      >
        <Message
          variant="warning"
          title={
            <Typography variant="h2-large">{t(`${lang}:pageTitle`)}</Typography>
          }
          mb={4}
        />
        <Typography variant="p" mx={2}>
          {t(`${lang}:p1`)}
        </Typography>
        <Typography variant="p" mx={2}>
          {t(`${lang}:p2-1`)}{' '}
          <Link
            href="https://www.sage.co.uk/desktop/redirect.aspx?dest=14726"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t(`${lang}:p2-link`)}
          </Link>{' '}
          {t(`${lang}:p2-2`)}
        </Typography>
        <Typography variant="p" mx={2}>
          {t(`${lang}:p3`)}
        </Typography>
        <Typography variant="p" mx={2} mb={6}>
          {t(`${lang}:p4-1`)}
          <br />
          {t(`${lang}:p4-2`)}
        </Typography>
      </Box>
    </>
  );
}

export default memo(ESSV1DeprecationInfo);
